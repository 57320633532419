import React, { useEffect } from 'react';

import { Field, FieldProps, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { IconInvalid } from '../../assets';

import TextArea from './TextArea';
import { ErrorMessage } from './TextInput';

interface FormikTextAreaFieldProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  rows?: number;
}

const FormikTextAreaField = ({
  label,
  id,
  name,
  placeholder,
  rows = 5,
}: FormikTextAreaFieldProps) => {
  const { setFieldTouched } = useFormikContext();

  useEffect(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return (
          <>
            <Label htmlFor={id} invalid={!!(meta.touched && meta.error)}>
              {label}
            </Label>
            <TextAreaWithErrorCapability
              error={!!(meta.touched && meta.error)}
              id={id}
              {...field}
              rows={rows}
              placeholder={placeholder}
            />
            {meta.touched && meta.error ? (
              <ErrorMessage>{meta.error}</ErrorMessage>
            ) : null}
          </>
        );
      }}
    </Field>
  );
};

interface LabelProps {
  invalid?: boolean;
}

const Label = styled.label<LabelProps>`
  ${(props) => css`
    padding: 0 0 ${props.theme.margin[8]} 0;
  `}

  display: block;
  font-weight: bold;
  ${(props) =>
    props.invalid &&
    css`
      color: ${props.theme.color.red};
    `}
`;

interface TextAreaWithErrorCapabilityProps {
  error: boolean;
}

const TextAreaWithErrorCapability = styled(
  TextArea
)<TextAreaWithErrorCapabilityProps>`
  border: ${({ theme, error }) =>
    error ? '1px solid red;' : `1px solid ${theme.color.graphiteB86}`};
  border-radius: ${({ theme }) => theme.radius.small};
  min-height: ${({ theme }) => theme.margin[96]};

  ${(props) =>
    props.error &&
    `background: ${props.theme.color.white} url(${IconInvalid}) no-repeat;
      background-position: right ${props.theme.margin[8]} top ${props.theme.margin[8]};
    `}
`;

export default FormikTextAreaField;
