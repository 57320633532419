import Big from 'big.js';

import { TargetRowOrTargetRowHierarchyEntry } from '../../hooks/useTargetViewData';

import { isDefined } from '../../../../utils/general';
import {
  getAllRowsFlattened,
  getSelectedRowsFlattenedWithSubrows,
} from '../../../../utils/tableUtils';

type MappedNewRow = {
  target: Big;
  orderId: string | null;
  workPackageId: string | undefined;
  orderCode?: string | undefined;
  orderName?: string | undefined;
  workPackageCode?: string | undefined;
  workPackageName?: string | undefined;
  topicName?: string | undefined;
  hasUnSavedChanges?:
    | boolean
    | {
        originalOrderId: string | null;
        originalWorkPackageId: string | null;
        originalTopicId: string | null;
        originalOrderCode: string | null;
        originalOrderName: string | null;
        originalWorkPackageCode: string | null;
        originalWorkPackageName: string | null;
        originalTopicName: string | null;
      };
  targetRowIds: string[];
};

export type UpdateProcurementRow = {
  id: string;
  type: 'order' | 'topic' | 'orderRow' | 'targetRow';
  originalId: string;

  targetRowHierarchyEntryId?: string | null;
  orderId?: string | null;
  workPackageId?: string | null;
  topicId?: string | null;
  topicName?: string | null;

  description: string | null;
  code: string | null;

  amount: Big;
  unitPrice: Big | null;
  quantity: Big | null;
  unit: string | null;

  // for searching purposes
  parentDescriptions: string;
  parentCodes: string;

  subRows?: UpdateProcurementRow[];
  hasUnSavedChanges?:
    | boolean
    | {
        originalOrderId: string | null;
        originalWorkPackageId: string | null;
        originalTopicId: string | null;
        originalOrderCode: string | null;
        originalOrderName: string | null;
        originalWorkPackageCode: string | null;
        originalWorkPackageName: string | null;
      };
};

const sortingFunction = (a: UpdateProcurementRow, b: UpdateProcurementRow) => {
  if (a.code && b.code) {
    return a.code.localeCompare(b.code);
  }

  if (a.code && !b.code) {
    return 1;
  }

  if (!a.code && b.code) {
    return -1;
  }

  return (a.description ?? '').localeCompare(b.description ?? '');
};

export const parseSelections = (
  selections: Record<string, boolean>,
  data: TargetRowOrTargetRowHierarchyEntry[]
): UpdateProcurementRow[] => {
  const selectedIds = new Set(
    Object.keys(selections).filter((key) => selections[key] === true)
  );

  const selectedRows = getSelectedRowsFlattenedWithSubrows(data, selectedIds);

  const mappedWithChildren = selectedRows.map((row) => ({
    ...row,
    children: getAllRowsFlattened([row]).filter(
      (childRow) =>
        childRow.type === 'targetRow' &&
        childRow.orderRowId === null &&
        childRow.isDeleted === false &&
        childRow.isAntiRow === false &&
        childRow.isDisabled === false
    ),
  }));

  const newOrderRows = mappedWithChildren
    .map((row) => {
      const totalTargetFromChildrenPerTopicId = row.children.reduce(
        (acc, childRow) => {
          const {
            topicId,
            orderId,
            workPackageId,
            orderCode,
            orderName,
            workPackageCode,
            workPackageName,
            hasUnSavedChanges,
            topicName,
          } = childRow;

          const unSavedChangesValue = hasUnSavedChanges ? true : undefined;

          const newTopicId =
            topicId ?? `new-topic-order-${orderId}-wp-${workPackageId}`;

          if (childRow.target && childRow.isDeleted === false && newTopicId) {
            const previousSum = acc[newTopicId]?.target;

            if (previousSum) {
              acc[newTopicId].target = previousSum.add(childRow.target);
              acc[newTopicId].targetRowIds.push(childRow.originalId);
              acc[newTopicId].hasUnSavedChanges = acc[newTopicId]
                .hasUnSavedChanges
                ? true
                : unSavedChangesValue;
            } else {
              acc[newTopicId] = {
                target: childRow.target,
                orderId,
                workPackageId,
                orderCode,
                orderName,
                workPackageCode,
                workPackageName,
                topicName: topicName ?? workPackageName,
                targetRowIds: [childRow.originalId],
                hasUnSavedChanges: unSavedChangesValue,
              };
            }
          }

          return acc;
        },
        {} as Record<string, MappedNewRow>
      );

      let targetRowKeyValuePairs: [string, UpdateProcurementRow][] = [];

      for (let i = 0; i < row.children.length; i++) {
        const childRow = row.children[i];

        const mappedChildRow = {
          originalId: childRow.originalId,
          id: childRow.id,
          type: 'targetRow' as const,
          amount: childRow.target ?? new Big(0),
          unitPrice: childRow.unitPrice,
          quantity: childRow.quantity,
          unit: childRow.unit,
          description: childRow.description,
          code: childRow.referenceNo,
          parentDescriptions: `${childRow.orderName} ${childRow.topicName} ${row.description}`,
          parentCodes: `${childRow.orderCode} ${childRow.workPackageCode} ${row.referenceNo}`,
          hasUnSavedChanges: childRow.hasUnSavedChanges,
        };

        targetRowKeyValuePairs.push([childRow.originalId, mappedChildRow]);
      }

      const targetRowMap: Map<string, UpdateProcurementRow> = new Map(
        targetRowKeyValuePairs
      );

      const newRows = Object.entries(totalTargetFromChildrenPerTopicId).map(
        ([topicId, newRow]) => {
          const unitPrice =
            !row.quantity || row.quantity.eq(0)
              ? new Big(1)
              : newRow.target.div(row.quantity);

          const quantity =
            !row.quantity || row.quantity.eq(0) ? newRow.target : row.quantity;

          return {
            id: `orderRow-${row.id}-${topicId}`,
            originalId: `orderRow-${row.originalId}-${topicId}`,
            topicId,
            targetRowHierarchyEntryId:
              row.type === 'targetRowHierarchyEntry' ? row.originalId : null,
            workPackageId: newRow.workPackageId,
            orderId: newRow.orderId,
            orderCode: newRow.orderCode,
            orderName: newRow.orderName,
            workPackageCode: newRow.workPackageCode,
            workPackageName: newRow.workPackageName,
            topicName: newRow.topicName,
            type: 'orderRow' as const,
            description: row.description,
            parentDescriptions: `${newRow.orderName} ${newRow.topicName}`,
            parentCodes: `${newRow.orderCode} ${newRow.workPackageCode}`,
            code: row.referenceNo,
            amount: newRow.target,
            unit: row.unit,
            unitPrice,
            quantity,
            hasUnSavedChanges: newRow.hasUnSavedChanges,
            subRows: newRow.targetRowIds
              .map((targetRowId) => targetRowMap.get(targetRowId))
              .filter(isDefined)
              .sort(sortingFunction),
          };
        }
      );

      return newRows;
    })
    .flat();

  const topicGroupedOrderRows = newOrderRows
    .reduce(
      (acc, row) => {
        const rows = acc;
        const { topicId } = row;

        const topic = {
          originalId: topicId,
          id: `topic-${topicId}`,
          type: 'topic' as const,
          description: row.topicName ?? '',
          orderCode: row.orderCode ?? '',
          orderName: row.orderName ?? '',
          orderId: row.orderId,
          code: row.workPackageCode ?? '',
          parentDescriptions: `${row.orderName}`,
          parentCodes: `${row.orderCode}`,
          amount: row.amount,
          unitPrice: null,
          quantity: null,
          unit: null,
          subRows: [row],
          hasUnSavedChanges: row.hasUnSavedChanges,
        };

        let previousRow = rows.find((group) => group.originalId === topicId);

        let indexOfPreviousRow = rows.findIndex(
          (group) => group.originalId === topicId
        );

        if (previousRow?.subRows) {
          previousRow.subRows.push(row);
          previousRow = {
            ...previousRow,
            amount: previousRow.amount.add(row.amount),
          };

          rows[indexOfPreviousRow] = previousRow;
        } else {
          rows.push(topic);
        }

        return rows;
      },
      [] as (UpdateProcurementRow & {
        orderId: string | null;
        orderCode: string;
        orderName: string;
      })[]
    )
    .sort(sortingFunction);

  const orderGroupedTopics = topicGroupedOrderRows.reduce((acc, row) => {
    const rows = acc;
    const { orderId } = row;

    const order = {
      originalId: orderId ?? '',
      id: `order-${orderId}`,
      type: 'order' as const,
      description: row.orderName ?? '',
      orderId: row.orderId,
      code: row.orderCode ?? '',
      amount: row.amount,
      unitPrice: null,
      quantity: null,
      unit: null,
      subRows: [row],
      parentDescriptions: '',
      parentCodes: '',
      hasUnSavedChanges: row.hasUnSavedChanges,
    };

    let previousRow = rows.find((group) => group.originalId === orderId);

    let indexOfPreviousRow = rows.findIndex(
      (group) => group.originalId === orderId
    );

    if (previousRow?.subRows) {
      previousRow.subRows.push(row);
      previousRow = {
        ...previousRow,
        amount: previousRow.amount.add(row.amount),
      };

      rows[indexOfPreviousRow] = previousRow;
    } else {
      rows.push(order);
    }

    return rows;
  }, [] as UpdateProcurementRow[]);

  return orderGroupedTopics.sort(sortingFunction);
};
